<template>
  <div>
    <transition name="el-zoom-in-top">
      <el-table v-if="showTable" :data="list" stripe style="width: 100%">
        <el-table-column prop="create_time" label="操作时间" width="180">
        </el-table-column>
        <el-table-column prop="operator_name" label="姓名" width="100">
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="content" label="内容"> </el-table-column>
        <el-table-column prop="web_url" label="网页url"> </el-table-column>
        <el-table-column prop="envStr" label="环境" width="100">
        </el-table-column>
        <el-table-column prop="deviceStr" label="推送设备" width="120">
        </el-table-column>
        <el-table-column prop="appversions" label="app版本"> </el-table-column>
        <el-table-column
          show-overflow-tooltip="true"
          prop="request"
          label="request"
          width="130"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip="true"
          prop="response"
          label="response"
          width="130"
        >
        </el-table-column>
      </el-table>
    </transition>
  </div>
</template>

<script>
import { getPushMsgHistory } from '@/api/pushMsg.js';

export default {
  name: 'History',
  props: {},
  data () {
    return {
      showTable: false,
      list: []
    };
  },

  methods: {},

  beforeCreate () {},
  created () {
    let that = this;
    getPushMsgHistory({})
      .then((res) => {
        console.log('推送历史记录获取成功：', res);
        let list = res.data;
        list.forEach((item) => {
          let deviceStr = '';
          let envStr = '';
          if (item.android == 1) {
            deviceStr += '安卓';
          }
          if (item.ios == 1) {
            deviceStr += ' iOS';
          }
          if (item.env.indexOf('unstable') > -1) {
            envStr = '测试环境';
          } else {
            envStr = '正式环境';
          }
          item.deviceStr = deviceStr;
          item.envStr = envStr;
        });
        that.list = list;
        that.showTable = true;
        console.log('12313', list);
      })
      .catch((error) => {
        console.log('推送历史记录获取失败：', error);
      });
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
